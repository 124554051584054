import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
  COMPLETE_TAKEDOWN_FAILURE,
  COMPLETE_TAKEDOWN_SUCCESS,
  CREATE_TAKEDOWN_FAILURE,
  CREATE_TAKEDOWN_SUCCESS,
  GET_HUBSPORTID_FAILURE,
  GET_HUBSPORTID_SUCCESS,
  GET_STATUS_TAKEDOWN_FAILURE,
  GET_STATUS_TAKEDOWN_SUCCESS,
  GET_TAKEDOWN_THREAT_DETAIL_FAILURE,
  GET_TAKEDOWN_THREAT_DETAIL_SUCCESS,
  GET_TAKEDOWNLIST_FAILURE,
  GET_TAKEDOWNLIST_SUCCESS,
} from '../constant';
import {
  completeTakedownApi,
  createTakedownApi,
  getHubSportIdApi,
  getTakedownDetailApi,
  getTakeDownListApi,
  getTakedownStatusApi,
} from '.';

export function* getTakeDownListSaga(action: any): any {
  try {
    const response = yield call(getTakeDownListApi, action.payload);
    yield put({
      type: GET_TAKEDOWNLIST_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_TAKEDOWNLIST_FAILURE,
      payload: error.message,
    });
  }
}

export function* createTakedownSaga(action: any): any {
  try {
    const response = yield call(createTakedownApi, action.payload);
    yield put({
      type: CREATE_TAKEDOWN_SUCCESS,
      payload: response.data,
    });
    toast.success('Takedown created successfully');
  } catch (error: any) {
    yield put({
      type: CREATE_TAKEDOWN_FAILURE,
      payload: error.response.data.detail,
    });
    toast.error(error.response.data.detail || 'Failed to create takedown');
  }
}

export function* completeTakedownSaga(action: any): any {
  try {
    const response = yield call(completeTakedownApi, action.payload);
    toast.success(response.data.message || 'Takedown completed successfully!');
    yield put({
      type: COMPLETE_TAKEDOWN_SUCCESS,
      payload: response.data,
    });
    const resGetTakedownList = yield call(getTakeDownListApi, {
      since: action.payload.since,
      until: action.payload.until,
      threat_domain: '',
      target_domain: '',
      company_id: '',
      page: 1,
      size: 100,
    });
    yield put({
      type: GET_TAKEDOWNLIST_SUCCESS,
      payload: resGetTakedownList.data,
    });
  } catch (error: any) {
    toast.error(error.response.data.detail || 'Failed to complete takedown');
    yield put({
      type: COMPLETE_TAKEDOWN_FAILURE,
      payload: error.response.data.detail,
    });
  }
}

export function* getHubSportIdSaga(action: any): any {
  try {
    const response = yield call(getHubSportIdApi, action.payload);
    yield put({
      type: GET_HUBSPORTID_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_HUBSPORTID_FAILURE,
      payload: error.message,
    });
  }
}

export function* getTakedownDetailSaga(action: any): any {
  try {
    const response = yield call(getTakedownDetailApi, action.payload);
    yield put({
      type: GET_TAKEDOWN_THREAT_DETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_TAKEDOWN_THREAT_DETAIL_FAILURE,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
}

export function* getTakedownStatusSaga(action: any): any {
  try {
    const response = yield call(getTakedownStatusApi, action.payload);
    yield put({
      type: GET_STATUS_TAKEDOWN_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_STATUS_TAKEDOWN_FAILURE,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
}
