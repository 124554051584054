import { CreateTakedownType, getTakeDownListType } from '../../helpers/types';
import { apiClient, apiClientTakeDown } from '../../services/apiClient';

export const getTakeDownListApi = async (payload: any) => {
  let url = `v2/takedown/?threat_domain=${payload.threat_domain}&target_domain=${payload.target_domain}&page=${payload.page}&size=${payload.size}`;
  if (payload.since && payload.until) {
    url += `&since=${payload.since}&until=${payload.until}`;
  }

  if (typeof payload.include_finished === 'boolean') {
    url += `&include_finished=${payload.include_finished}`;
  }

  if (typeof payload.status_names === 'string') {
    url += `&status_names=${payload.status_names}`;
  }

  if (
    payload.company_id &&
    Array.isArray(payload.company_id) &&
    payload.company_id.length > 0
  ) {
    url += `&company_id=${payload.company_id.join(',')}`;
  }

  const config = {
    method: 'GET',
    url: url,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const createTakedownApi = async (data: CreateTakedownType) => {
  const config = {
    method: 'POST',
    url: `v2/takedown/`,
    data,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const completeTakedownApi = async (data: { id: string }) => {
  const config = {
    method: 'POST',
    url: `v2/takedown/${data.id}/status`,
    data,
  };
  const res = await apiClientTakeDown(config);
  return res;
};

export const getHubSportIdApi = async (ThreatName: string) => {
  const config = {
    method: 'GET',
    url: `domain/getwi?d=${ThreatName}`,
  };
  const res = await apiClient(config);
  return res;
};

export const getTakedownDetailApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `v2/assets/${payload.asset_id}/threats/${payload.threat_id}/takedowns`,
  };
  return await apiClientTakeDown(config);
};

export const getTakedownStatusApi = async (payload: any) => {
  const config = {
    method: 'GET',
    url: `v2/takedown/statuses`,
  };
  const res = await apiClientTakeDown(config);
  return res;
};
