import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OutputIcon from '@mui/icons-material/Output';
import { ExternalToolListWrapper } from './Style/SingleThreat.style';
import GenerateReportModal from './GenerateReportModal';
import CompleteTakedownModal from './CompleteTakedownModal';
import { Button, Loader } from '../../components';
import { ThreatTypes } from '../../helpers/types';
import {
  completeTakedownAction,
  createTakedownAction,
  getHubSportIdAction,
} from '../../store/takedown/action';

const CustomStyle = {
  boxShadow: 'none',
  textWrap: 'nowrap',
  backgroundColor: '#291b67',
};

const ExternalToolList = ({
  singleThreat,
  imageData,
}: {
  singleThreat: ThreatTypes | null;
  imageData: string | null;
}) => {
  const [openThreatReport, setOpenThreatReport] = useState<boolean>(false);
  const [openCompleteTakedown, setOpenCompleteTakedown] = useState(false);
  const [takedownMessage, setTakedownMessage] = useState<string>('');
  const [selectedStatusTakedown, setSelectedStatusTakedown] =
    useState<string>('');

  const dispatch = useDispatch();
  const { takedownList, createTakedown, getStatustakeDown } = useSelector(
    (state: any) => state.takedown,
  );

  useEffect(() => {
    singleThreat?.identification_status?.value === 'confirmed' &&
      dispatch(getHubSportIdAction(singleThreat?.protected_asset_name));
  }, [singleThreat?.identification_status?.value]);

  const finishedDescription = takedownList?.data?.items?.map(
    (item: any) => item.finished_description,
  );

  const hasSuccessStatus = finishedDescription?.find(
    (item: any) => item?.status === 'completed',
  );

  const handleCloseThreatReport = () => {
    setOpenThreatReport(false);
  };

  const handleCloseCompleteTakedown = () => {
    setOpenCompleteTakedown(false);
  };

  const handleCreateTakedown = () => {
    dispatch(
      createTakedownAction({
        initial_status: 'ongoing',
        threat_id: singleThreat?.threat_id,
      }),
    );
  };

  const handleChangeStatusTakedown = (e: any) => {
    setSelectedStatusTakedown(e.target.value);
  };

  const statusTakedownData = useCallback(
    getStatustakeDown?.data?.map((item: any) => ({
      label: item.name,
      value: item.name,
    })),
    [getStatustakeDown?.data],
  );

  const handleCompleteTakedown = () => {
    const takeDownItems = takedownList?.data?.items || [];
    const lastTakedown =
      takeDownItems.length > 0 ? takeDownItems[takeDownItems.length - 1] : '';
    if (singleThreat && lastTakedown) {
      dispatch(
        completeTakedownAction({
          id: lastTakedown.id,
          description: takedownMessage,
          status_name: selectedStatusTakedown,
          asset_id: singleThreat.protected_asset_id, // for get API
          threat_id: singleThreat.threat_id,
        }),
      );
      setTakedownMessage('');
      setOpenCompleteTakedown(false);
    }
  };

  return (
    <>
      <Box sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }}>
        <Button
          sx={{ ...CustomStyle, backgroundColor: '#1fc2d5' }}
          variant="contained"
          onClick={() => setOpenThreatReport(true)}
          startIcon={<FileDownloadIcon />}>
          Export
        </Button>
        {singleThreat?.identification_status?.value === 'confirmed' && (
          <Button
            sx={{ ...CustomStyle, backgroundColor: '#f90307' }}
            variant="contained"
            disabled={hasSuccessStatus}
            onClick={handleCreateTakedown}>
            {createTakedown.loading ? <Loader size={22} /> : 'Create Takedown'}
          </Button>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {singleThreat?.identification_status?.value === 'confirmed' &&
          takedownList.data.items?.length > 0 &&
          !hasSuccessStatus && (
            <Button
              sx={{ ...CustomStyle, backgroundColor: '#f90307' }}
              variant="contained"
              onClick={() => setOpenCompleteTakedown(true)}>
              Complete Takedown
            </Button>
          )}
      </Box>
      <ExternalToolListWrapper>
        <Link to="https://urlscan.io/domain/sapyny.co.uk" target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            URL Scan
          </Button>
        </Link>
        <Link
          to="https://web.archive.org/web/20220000000000*/sapyny.co.uk"
          target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            Wayback machine
          </Button>
        </Link>
        <Link
          to="https://transparencyreport.google.com/safe-browsing/search?url=sapyny.co.uk"
          target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            GSB
          </Button>
        </Link>
        <Link to="https://crt.sh/?q=sapyny.co.uk" target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            Certh.sh
          </Button>
        </Link>
        <Link
          to="https://www.virustotal.com/gui/domain/sapyny.co.uk"
          target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            VT
          </Button>
        </Link>
        <Link
          to="https://threatintelligenceplatform.com/report/sapyny.co.uk/vnrzbY2T1F"
          target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            TIP
          </Button>
        </Link>
        <Link to="https://dnschecker.org/#A/sapyny.co.uk" target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            DNS checker
          </Button>
        </Link>
        <Link to="https://www.whois.com" target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            Domain Tools
          </Button>
        </Link>
      </ExternalToolListWrapper>
      <GenerateReportModal
        openThreatReport={openThreatReport}
        handleClose={handleCloseThreatReport}
        singleThreat={singleThreat}
        imageData={imageData}
      />
      <CompleteTakedownModal
        handleClose={handleCloseCompleteTakedown}
        openCompleteTakedown={openCompleteTakedown}
        handleCompleteTakedown={handleCompleteTakedown}
        takedownMessage={takedownMessage}
        setTakedownMessage={setTakedownMessage}
        handleChangeStatusTakedown={handleChangeStatusTakedown}
        statusTakedownData={statusTakedownData}
      />
    </>
  );
};

export default ExternalToolList;
