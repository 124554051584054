import React, { useEffect, useState } from 'react';
import { DialogTitle, Box, Modal, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, GradientButton, Input, Loader } from '../../components';
import { ThreatNotesModalType } from '../../helpers/types';
import ThreatCommentsListCard from './ThreatCommentsListCard';
import {
  CommentsWrapper,
  CreateCommentsBtn,
  NotesHeader,
  NotesWrapper,
  ViewModalStyle,
  ViewNotesWrapper,
} from './Style/ViewThreatNotesModal.style';
import {
  createCommentsAction,
  createNotesAction,
  getNotesIdAction,
  getCommentsAction,
} from '../../store/noteComments/action';
import NoteList from '../../components/NoteList/NoteList';

const ViewThreatNotesModal = ({
  openThreatNoteModal,
  handleCloseThreatNote,
  items,
}: ThreatNotesModalType) => {
  const [addThreatComments, setAddThreatComments] = useState<string>('');
  const [selectedNoteIds, setSelectedNoteIds] = useState<number[]>([]);

  const dispatch = useDispatch();
  const { getComments, getNotesValues, getNoteId } = useSelector(
    (state: any) => state.noteComments,
  );
  const getCommentsList = getComments?.data?.items;
  const { threat_id, protected_asset_id } = items;

  useEffect(() => {
    if (openThreatNoteModal) {
      dispatch(
        getNotesIdAction({
          domain_id: items?.protected_asset_id,
          threat_id: items?.threat_id,
          page: 1,
          size: 50,
        }),
      );
      dispatch(
        getCommentsAction({
          domain_id: items?.protected_asset_id,
          threat_id: items?.threat_id,
        }),
      );
    }
  }, [openThreatNoteModal]);

  useEffect(() => {
    if (getNoteId?.data?.items) {
      const noteIds = getNoteId.data.items.map(
        (item: { note_id: number }) => item.note_id,
      );
      setSelectedNoteIds(noteIds);
    }
  }, [getNoteId]);

  const handleCreateComments = () => {
    if (!addThreatComments.trim()) {
      return;
    }
    dispatch(
      createCommentsAction({
        items: [
          {
            comments: addThreatComments,
            domain_id: items?.protected_asset_id,
            threat_id: items?.threat_id,
            is_public: true,
          },
        ],
      }),
    );
    setAddThreatComments('');
  };

  const handleChangeComments = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddThreatComments(e.target.value);
  };

  const handleApplyNotes = () => {
    const items = selectedNoteIds.map(noteId => ({
      domain_id: protected_asset_id,
      threat_id: threat_id,
      note_id: noteId,
    }));
    dispatch(
      createNotesAction({
        items,
      }),
    );
  };

  const handleNotesChange = (noteId: number) => {
    setSelectedNoteIds((prevSelectedNoteIds: any) =>
      prevSelectedNoteIds.includes(noteId)
        ? prevSelectedNoteIds.filter((id: any) => id !== noteId)
        : [...prevSelectedNoteIds, noteId],
    );
  };

  return (
    <>
      <Modal open={openThreatNoteModal} onClose={handleCloseThreatNote}>
        <Box sx={ViewModalStyle}>
          <NotesHeader>
            <DialogTitle sx={{ m: 0, p: 0 }}>Notes</DialogTitle>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={handleCloseThreatNote}
                variant="outlined"
                color="secondary"
                label="Cancel"
              />
            </Box>
          </NotesHeader>
          <ViewNotesWrapper>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <Typography sx={{ mb: 1 }}>Threat Notes List:</Typography>
                <NotesWrapper>
                  {getNotesValues.loading ? (
                    <Box sx={{ m: 12 }}>
                      <Loader size={25} />
                    </Box>
                  ) : (
                    <Grid container spacing={{ xs: 1.5 }}>
                      <NoteList
                        handleNotesChange={handleNotesChange}
                        selectedNoteIds={selectedNoteIds}
                        handleApplyNotes={handleApplyNotes}
                      />
                    </Grid>
                  )}
                </NotesWrapper>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={{ mb: 1 }}>Threat Comments:</Typography>
                <CommentsWrapper>
                  {getComments.loading ? (
                    <Box sx={{ m: 12 }}>
                      <Loader size={25} />
                    </Box>
                  ) : getCommentsList?.length === 0 ? (
                    <Typography
                      variant="subtitle1"
                      sx={{ color: 'black', m: 10 }}>
                      No Comments
                    </Typography>
                  ) : (
                    getCommentsList?.map((comments: any, index: number) => (
                      <ThreatCommentsListCard key={index} comments={comments} />
                    ))
                  )}
                </CommentsWrapper>
                <Input
                  placeholder="comments"
                  size="small"
                  multiline
                  rows={3}
                  value={addThreatComments}
                  sx={{ width: '100%' }}
                  onChange={handleChangeComments}
                />
                <GradientButton
                  label="Send"
                  sx={CreateCommentsBtn}
                  onClick={handleCreateComments}
                  disabled={!addThreatComments.trim()}
                />
              </Grid>
            </Grid>
          </ViewNotesWrapper>
        </Box>
      </Modal>
    </>
  );
};

export default ViewThreatNotesModal;
