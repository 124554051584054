import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import store, { persistor } from './store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import { providerConfig } from './helpers/auth0Config';
import { MyFallbackComponent } from './helpers/ErrorBoundary';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const theme = createTheme({
  typography: {
    fontFamily: ['TT Norms Pro', 'sans-serif'].join(','),
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <Auth0Provider {...providerConfig}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            <App />
          </PersistGate>
        </Provider>
      </Auth0Provider>
    </ErrorBoundary>
  </ThemeProvider>,
);

reportWebVitals();
