import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Pagination,
  Switch,
  FormControlLabel,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import LanguageIcon from '@mui/icons-material/Language';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';
import FilterCard from './FilterCard';
import {
  changeStatusLabelAction,
  getDomainsListAction,
  getThreatAction,
  resetThreatAction,
  setStatusPersistValues,
  toggleInfiniteScroll,
} from '../../store/threats/action';
import {
  defaultEndDate,
  defaultStartDate,
  defaultStatusLabel,
  getLabelsAndIds,
  threatsStatus,
} from '../../utils';
import {
  CustomTab,
  InputContainer,
  ThreatCardWrapper,
  TotalCard,
  TotalText,
  ThreatCardContainer,
  CommonNotesWrapper,
} from './Style/Home.style';
import {
  Button,
  SearchBar,
  StatusMenu,
  ConfirmationModal,
  ThreatCard,
  ThreatsSkeleton,
  GradientButton,
} from '../../components';
import {
  BulkThreatDataType,
  ThreatTypes,
  selectedThreatDataType,
} from '../../helpers/types';
import CreateThreatModel from './CreateThreatModel';
import { PAGE_NAME } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import AddCommonNotesModal from './AddCommonNotesModal';
import {
  setCompanyPersistValues,
  setDatePickerPersistValues,
} from '../../store/company/action';
import { getNotesValuesAction } from '../../store/noteComments/action';

const Home = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedThreats, setSelectedThreats] = useState<any>([]); // TODO:
  const [currentTab, setCurrentTab] = useState('1');
  const [openStatusMenu, setOpenStatusMenu] = useState<null | HTMLElement>(
    null,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [statuesFilterValues, setStatuesFilterValues] =
    useState<{ label: string }[]>(defaultStatusLabel);
  const [companyFilterValues, setCompanyFilterValues] = useState<string[]>([]);
  const [startDateFilterValues, setStartDateFilterValues] =
    useState<string>(defaultStartDate);
  const [endDateFilterValues, setEndDateFilterValues] =
    useState<string>(defaultEndDate);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedBulkStatusLabel, setSelectedBulkStatusLabel] =
    useState<string>('');
  const [openAddThreatModal, setOpenAddThreatModal] = useState<boolean>(false);
  const [hasMoreThreats, setHasMoreThreats] = useState<boolean>(true);
  const [isInfiniteScroll, setIsInfiniteScroll] = useState(false);
  const [openCommonThreatModal, setOpenCommonThreatModal] =
    useState<boolean>(false);
  const [openSortBy, setOpenSortBy] = useState<null | HTMLElement>(null);

  const dispatch = useDispatch();
  const { user } = useAuth0();

  const {
    threatList: threats,
    loading: threatsLoader,
    statusPersistValues,
  } = useSelector((state: any) => state.threats);
  const { companyPersistValues, datePickerPersistValues } = useSelector(
    (state: any) => state.company,
  );

  const selectedCompanyLabels = getLabelsAndIds(companyPersistValues, 'id');
  const selectedStatusLabels = getLabelsAndIds(statusPersistValues, 'label');
  const defaultStatusLabels = getLabelsAndIds(statuesFilterValues, 'label');

  const getThreatActionProps = {
    currentPage,
    searchValue,
    endDateFilterValues,
    startDateFilterValues,
    selectedCompanyLabels,
    selectedStatusLabels,
    statuesFilterValues,
  };

  const fetchThreats = (sortOrder = 'DESCENDING', sortBy = 'updated_at') => {
    const params = {
      from: startDateFilterValues,
      until: endDateFilterValues,
      status: statuesFilterValues ? selectedStatusLabels : 'detected',
      threat_name: searchValue,
      company_ids: selectedCompanyLabels,
      sort: sortBy,
      order: sortOrder,
      page: currentPage,
      size: 16,
    };
    dispatch(getThreatAction(params));
  };

  useEffect(() => {
    if (currentTab === '1' || currentTab === '2') {
      fetchThreats();
    }
    dispatch(getNotesValuesAction());
  }, [currentPage, currentTab]);

  const handleSortBy = (order = 'detected_at', sortBy = 'updated_at') => {
    setOpenSortBy(null);
    if (currentTab === '1' || currentTab === '2') {
      fetchThreats(order, sortBy);
    }
  };

  const handleUpdatedASCE = () => handleSortBy('ASCENDING', 'updated_at');
  const handleUpdatedDESC = () => handleSortBy('DESCENDING', 'updated_at');
  const handleDetectedASCE = () => handleSortBy('ASCENDING', 'detected_at');
  const handleDetectedDESC = () => handleSortBy('DESCENDING', 'detected_at');

  const handleApplyFilter = () => {
    dispatch(resetThreatAction());
    setCurrentPage(1);
    setHasMoreThreats(true);
    dispatch(
      getThreatAction({
        from: startDateFilterValues,
        until: endDateFilterValues,
        status: selectedStatusLabels,
        threat_name: searchValue,
        company_ids: selectedCompanyLabels,
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: 16,
      }),
    );
  };

  const handleResetFilter = () => {
    setStartDateFilterValues(defaultStartDate);
    setEndDateFilterValues(defaultEndDate);
    // dispatch(
    //   setDatePickerPersistValues({
    //     startDate: defaultStartDate,
    //     endDate: defaultEndDate,
    //   }),
    // );
    dispatch(setStatusPersistValues(defaultStatusLabel));
    dispatch(setCompanyPersistValues([]));
    setSearchValue('');
    setCompanyFilterValues([]);
    setCurrentPage(1);
    dispatch(resetThreatAction());
    dispatch(
      getThreatAction({
        from: startDateFilterValues,
        until: endDateFilterValues,
        status: defaultStatusLabels,
        company_ids: 0,
        threat_name: '',
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: 16,
      }),
    );
  };

  const handleSelectAll = useCallback(() => {
    if (selectedThreats?.length === threats?.items?.length) {
      setSelectedThreats([]);
    } else {
      const selectedThreats: any = [];
      threats?.items?.map((threat: ThreatTypes) => {
        selectedThreats.push({
          domain: threat.protected_asset_name,
          threat: threat.threat_name,
          threatId: threat.threat_id,
          companyId: threat.company_id,
          domainId: threat.protected_asset_id,
          currentStatus: threat.identification_status.value,
        });
      });
      setSelectedThreats(selectedThreats);
    }
  }, [selectedThreats]);

  const onChangeSatatuses = (
    e: React.SyntheticEvent,
    value: { label: string }[],
  ) => {
    dispatch(setStatusPersistValues(value));
  };

  const onChangeCompany = (e: React.SyntheticEvent, value: string[]) => {
    dispatch(setCompanyPersistValues(value));
  };

  const onChangeDate = (value: any) => {
    if (value) {
      const formattedStartDate = dayjs(value[0]).format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = dayjs(value[1])
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      setStartDateFilterValues(formattedStartDate);
      setEndDateFilterValues(formattedEndDate);
      dispatch(
        setDatePickerPersistValues({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        }),
      );
    } else {
      setStartDateFilterValues('');
      setEndDateFilterValues('');
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentPage(1);
    setCurrentTab(newValue);
  };

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenStatusMenu(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setOpenStatusMenu(null);
  };

  const handleConfirmModal = (value: string) => {
    setSelectedBulkStatusLabel(value);
    setOpenStatusMenu(null);
    setOpenConfirmModal(true);
  };

  const updateBulkThreatsStatus = () => {
    setOpenConfirmModal(false);
    const analytic = {
      pageName: PAGE_NAME.HOME_PAGE,
      user: { email: user?.email, name: user?.name },
      isBulkUpdate: true,
    };
    dispatch(setAnalyticObject(analytic));
    selectedThreats.forEach((item: BulkThreatDataType) => {
      const newData = {
        threat: item.threat,
        domain: item.domain,
        priority: '90',
        status: selectedBulkStatusLabel,
        process: 'w',
        userId: user?.sub,
        threatId: item.threatId,
        companyId: item.companyId,
        domainId: item.domainId,
        currentStatus: item.status,
      };
      dispatch(changeStatusLabelAction(newData));
    });
    setSelectedThreats([]);
  };

  const handleChangeSelectedThreats = (threatsItem: selectedThreatDataType) => {
    const indexOf = selectedThreats.findIndex(
      (threat: selectedThreatDataType) =>
        threat.threatId === threatsItem.threatId &&
        threat.domainId === threatsItem.domainId &&
        threat.companyId === threatsItem.companyId,
    );
    if (indexOf > -1) {
      const newSelectedThreats = [...selectedThreats];
      newSelectedThreats.splice(indexOf, 1);
      setSelectedThreats([...newSelectedThreats]);
    } else {
      setSelectedThreats([...selectedThreats, threatsItem]);
    }
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = () => {
    dispatch(
      getThreatAction({
        from: startDateFilterValues,
        until: endDateFilterValues,
        status: selectedStatusLabels,
        threat_name: searchValue,
        company_ids: 0,
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: 16,
      }),
    );
  };

  const handleAddThreatModal = () => {
    setOpenAddThreatModal(true);
    dispatch(getDomainsListAction(''));
  };

  const count = Math.ceil(threats?.total / 16);
  const handleChangePage = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handleToggleView = (event: any) => {
    setCurrentPage(1);
    dispatch(resetThreatAction());
    setIsInfiniteScroll(event.target.checked);
    dispatch(toggleInfiniteScroll(event.target.checked));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <TotalCard>
            <Box>
              <Typography>Total</Typography>
              <Typography sx={TotalText}>{threats?.total}</Typography>
            </Box>
            <LanguageIcon />
          </TotalCard>
        </Grid>
        <Grid item md={8}>
          <FilterCard
            onChangeSatatuses={onChangeSatatuses}
            onChangeCompany={onChangeCompany}
            onChangeDate={onChangeDate}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
            statuesFilterValues={statuesFilterValues}
            companyFilterValues={companyFilterValues}
            defaultStartDate={datePickerPersistValues?.startDate}
            defaultEndDate={datePickerPersistValues?.endDate}
            openSortBy={openSortBy}
            setOpenSortBy={setOpenSortBy}
            handleUpdatedASCE={handleUpdatedASCE}
            handleUpdatedDESC={handleUpdatedDESC}
            handleDetectedASCE={handleDetectedASCE}
            handleDetectedDESC={handleDetectedDESC}
          />
        </Grid>
      </Grid>
      <Grid item md={12} sx={InputContainer}>
        <Box sx={{ display: 'flex' }}>
          <SearchBar
            size="small"
            handleChange={handleChangeSearch}
            handleSearch={handleSearchSubmit}
            searchValue={searchValue}
          />
          <Box sx={{ ml: 2 }}>
            <GradientButton onClick={handleAddThreatModal} sx={{ mr: 2 }}>
              + Add Threat
            </GradientButton>
            <FormControlLabel
              control={
                <Switch
                  checked={isInfiniteScroll}
                  onChange={handleToggleView}
                />
              }
              label="Infinite Scroll View"
            />
          </Box>
        </Box>
        {selectedThreats?.length > 0 && (
          <Box sx={{ gap: 1, display: 'flex' }}>
            <InputLabel sx={{ mt: '10px', color: 'black' }}>
              {selectedThreats?.length} Selected
            </InputLabel>
            <GradientButton
              variant="contained"
              size="medium"
              label={
                selectedThreats?.length === threats?.items?.length
                  ? 'Deselected All'
                  : 'Select All'
              }
              onClick={handleSelectAll}
            />
            <CommonNotesWrapper>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                label="Change Status"
                onClick={handleOpenStatusMenu}
              />
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                label="Add Notes"
                onClick={() => setOpenCommonThreatModal(true)}
              />
            </CommonNotesWrapper>
          </Box>
        )}
      </Grid>
      <Box sx={{ width: '100%', mt: 2 }}>
        <TabContext value={currentTab}>
          <TabList onChange={handleChangeTab}>
            <CustomTab label="Daily Threat" value="1" />
          </TabList>
          <TabPanel value="1" sx={{ px: 0, py: 0 }}>
            {isInfiniteScroll ? (
              <InfiniteScroll
                dataLength={threats?.items?.length || 0}
                next={() => setCurrentPage(currentPage + 1)}
                hasMore={hasMoreThreats}
                loader={threatsLoader && <ThreatsSkeleton />}>
                <ThreatCardContainer>
                  {!threatsLoader && threats?.items?.length === 0 ? (
                    <ThreatCardWrapper>No Data Found</ThreatCardWrapper>
                  ) : (
                    <Grid container spacing={{ xs: 2 }} columns={{ md: 12 }}>
                      {threats?.items?.map(
                        (items: ThreatTypes, index: number) => (
                          <Grid item md={3} key={index}>
                            <ThreatCard
                              items={items}
                              handleChangeSelectedThreats={
                                handleChangeSelectedThreats
                              }
                              selectedThreats={selectedThreats}
                              getThreatActionProps={getThreatActionProps}
                            />
                          </Grid>
                        ),
                      )}
                    </Grid>
                  )}
                </ThreatCardContainer>
              </InfiniteScroll>
            ) : (
              <ThreatCardContainer>
                <PaginationStyle style={{ marginTop: '-16px' }}>
                  <Pagination
                    count={count || 0}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                  />
                </PaginationStyle>
                {threatsLoader ? (
                  <ThreatsSkeleton />
                ) : threats?.items?.length === 0 ? (
                  <ThreatCardWrapper>No Data Found</ThreatCardWrapper>
                ) : (
                  <Grid container spacing={{ xs: 2 }} columns={{ md: 12 }}>
                    {threats?.items?.map(
                      (items: ThreatTypes, index: number) => (
                        <Grid item md={3} key={index}>
                          <ThreatCard
                            items={items}
                            handleChangeSelectedThreats={
                              handleChangeSelectedThreats
                            }
                            selectedThreats={selectedThreats}
                            getThreatActionProps={getThreatActionProps}
                          />
                        </Grid>
                      ),
                    )}
                  </Grid>
                )}
              </ThreatCardContainer>
            )}
          </TabPanel>
        </TabContext>
      </Box>
      <CreateThreatModel
        open={openAddThreatModal}
        handleClose={() => setOpenAddThreatModal(false)}
      />
      <AddCommonNotesModal
        open={openCommonThreatModal}
        handleClose={() => setOpenCommonThreatModal(false)}
        selectedThreats={selectedThreats}
      />
      <StatusMenu
        items={threatsStatus}
        openStatusMenu={openStatusMenu}
        closeStatusMenu={handleCloseStatusMenu}
        handleOnClick={handleConfirmModal}
      />
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        handleUpdate={() => updateBulkThreatsStatus()}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Home;
