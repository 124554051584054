import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  ButtonsStyle,
  CheckboxStyle,
  FormControlLabelStyle,
} from '../../pages/ThreadsDetails/Style/SingleThreat.style';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';

const NoteList = ({
  handleNotesChange,
  selectedNoteIds,
  handleApplyNotes,
}: any) => {
  const { getNotesValues, createNotes } = useSelector(
    (state: any) => state.noteComments,
  );

  return (
    <>
      {getNotesValues?.data?.map((item: any, index: number) => (
        <Grid item xs={6} key={index}>
          <Typography variant="body1" sx={{ my: 1, color: 'black' }}>
            {item.name}
          </Typography>
          {item?.note_values?.map((item: any, index: number) => (
            <Tooltip title={item.description} arrow key={index}>
              <FormControlLabel
                sx={FormControlLabelStyle}
                control={
                  <Checkbox
                    sx={CheckboxStyle}
                    size="small"
                    checked={selectedNoteIds.includes(item.id)}
                    onChange={() => handleNotesChange(item.id)}
                  />
                }
                label={item.name}
              />
            </Tooltip>
          ))}
        </Grid>
      ))}
      <ButtonsStyle>
        <Button
          variant="contained"
          startIcon={<CheckCircleIcon />}
          sx={{ background: '#0fd6c2', mr: 1, minWidth: 90 }}
          disabled={selectedNoteIds.length === 0 || createNotes.loading}
          onClick={handleApplyNotes}>
          {createNotes.loading ? <Loader size={18} /> : 'Apply'}
        </Button>
        <Button variant="outlined">Cancel</Button>
      </ButtonsStyle>
    </>
  );
};

export default NoteList;
