/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Card,
  Avatar,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  ProgressBar,
  StatusLabel,
  ConfirmationModal,
  ImageSkeleton,
  GradientButton,
} from '..';
import { ThreatCardProps, selectedThreatDataType } from '../../helpers/types';
import { getStatusColor } from '../../utils';
import {
  CardContent,
  CardWrapper,
  CardTitle,
  LoaderWrapper,
  StatusWrapper,
  CardActions,
} from './ThreatCard.style';
import { getThreatScreenshot } from '../../store/threats';
import Avtar from '../../assets/Images/Avtar.png';
import Toaster from '../Toaster/Toaster';
import {
  changeStatusLabelAction,
  getThreatAction,
} from '../../store/threats/action';
import ScreenShotModal from '../ScreenShotModal/ScreenShotModal';
import ThreatNotesModal from '../../pages/Home/ViewThreatNotesModal';
import { PAGE_NAME } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';

const ThreatDetails = {
  textDecoration: 'none',
  color: 'black',
};

const ThreatCard = ({
  items,
  handleChangeSelectedThreats,
  selectedThreats,
  getThreatActionProps,
}: ThreatCardProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [imageData, setImageData] = useState<string | null>(null);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [statusLabelThreat, setStatusLabelThreat] = useState('');
  const [isToasterStatus, setIsToasterStatus] = useState<boolean>(false);
  const [openScreenShotModal, setOpenScreenShotModal] =
    useState<boolean>(false);
  const [openThreatNoteModal, setOpenThreatNoteModal] =
    useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);

  const {
    currentPage,
    searchValue,
    endDateFilterValues,
    startDateFilterValues,
    selectedCompanyLabels,
    selectedStatusLabels,
    statuesFilterValues,
  } = getThreatActionProps;

  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { getCompany } = useSelector((state: any) => state.company);

  useEffect(() => {
    const getIamges = async () => {
      try {
        setImageLoader(true);
        const res = await getThreatScreenshot(items.threat_name);
        setImageData(res.data.Image);
      } catch (error) {
        console.error('Error fetching threat screenshot', error);
        setImageData(null);
      } finally {
        setImageLoader(false);
      }
    };
    getIamges();
  }, [items.threat_name]);

  useEffect(() => {
    if (items.error) {
      setIsToasterStatus(true);
    }
  }, [items.error]);

  const handleCloseScreenShot = () => {
    setOpenScreenShotModal(false);
  };

  const handleCloseThreatNote = () => setOpenThreatNoteModal(false);

  const isThreatSelected = useCallback(
    (threatData: selectedThreatDataType) => {
      const { companyId, domainId, threatId } = threatData;
      const indexOf = selectedThreats.findIndex(
        (threat: selectedThreatDataType) =>
          threat.threatId === threatId &&
          threat.domainId === domainId &&
          threat.companyId === companyId,
      );
      return indexOf > -1;
    },
    [selectedThreats],
  );

  const handleCopyThreatName = () => {
    navigator.clipboard.writeText(items.threat_name);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const updateSingleStatus = async () => {
    const {
      protected_asset_name,
      protected_asset_id,
      threat_name,
      threat_id,
      company_id,
      identification_status,
    } = items;
    const data = {
      domain: protected_asset_name,
      domainId: protected_asset_id,
      companyId: company_id,
      threat: threat_name,
      status: statusLabelThreat,
      priority: '90',
      process: 'w',
      userId: user?.sub,
      threatId: threat_id,
      currentStatus: identification_status.value,
    };
    setOpenModal(false);
    const analytic = {
      pageName: PAGE_NAME.HOME_PAGE,
      user: { email: user?.email, name: user?.name },
      isBulkUpdate: false,
    };
    dispatch(setAnalyticObject(analytic));
    await dispatch(changeStatusLabelAction(data));
    dispatch(
      getThreatAction({
        from: startDateFilterValues,
        until: endDateFilterValues,
        status: statuesFilterValues ? selectedStatusLabels : 'detected',
        company_ids: selectedCompanyLabels,
        threat_name: searchValue,
        page: currentPage,
        size: 16,
      }),
    );
  };

  const handleCloseToaster = (threatId: number) => {
    setIsToasterStatus(false);
  };

  const handleOpenSingleModal = (value: string) => {
    setStatusLabelThreat(value);
    setOpenModal(true);
  };

  const companies = getCompany?.data?.find(
    (c: any) => c?.Id === items?.company_id,
  );

  return (
    <>
      <Box>{items.isLoading && <ProgressBar />}</Box>
      <Card sx={items.isLoading ? LoaderWrapper : CardWrapper}>
        {isToasterStatus && (
          <Toaster
            title={items.error || 'Something want wrong'}
            backgroundColor="red"
            onCloseToaster={() => handleCloseToaster(items.threat_id)}
          />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tooltip title={items.threat_name} placement="top">
            <FormControlLabel
              sx={CardTitle}
              control={
                <>
                  <Checkbox
                    checked={isThreatSelected({
                      threatId: items.threat_id,
                      companyId: items.company_id,
                      domainId: items.protected_asset_id,
                    })}
                    onChange={() =>
                      handleChangeSelectedThreats({
                        domain: items.protected_asset_name,
                        threat: items.threat_name,
                        threatId: items.threat_id,
                        companyId: items.company_id,
                        domainId: items.protected_asset_id,
                        status: items.identification_status.value,
                      })
                    }
                    sx={{ paddingRight: '0' }}
                  />
                </>
              }
              label={
                items?.threat_name?.length > 20
                  ? items.threat_name.substring(0, 20) + '...'
                  : items.threat_name
              }
            />
          </Tooltip>
          <IconButton
            onClick={handleCopyThreatName}
            size="small"
            sx={{ padding: '0' }}
            disabled={copied}>
            <ContentCopyIcon sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Box>
        <StatusWrapper>
          <StatusLabel
            label={items?.identification_status?.value}
            bgcolor={getStatusColor(items?.identification_status?.value)}
          />
        </StatusWrapper>
        <Link
          to={`/threats/${encodeURIComponent(
            items?.threat_name,
          )}/${encodeURIComponent(
            items?.protected_asset_name,
          )}/${encodeURIComponent(
            items?.company_id,
          )}?protected_id=${encodeURIComponent(
            items?.protected_asset_id,
          )}&threat_id=${encodeURIComponent(items?.threat_id)}`}
          target="_blank"
          style={ThreatDetails}>
          <Stack sx={{ fontSize: '13px', margin: '8px 0' }}>
            Domain: {items?.protected_asset_name} <br />
            Company: {companies?.Name}
            <br />
            {items?.whois?.Registration && (
              <>
                Registration: {items.whois?.Registration} <br />
              </>
            )}
            {items?.whois?.Expiration && (
              <>
                Expiration: {items?.whois?.Expiration} <br />
              </>
            )}
            CTI Score: {items?.score?.value || 0}
            <br />
            {items.whois?.Registrar && <>Registrar: {items.whois?.Registrar}</>}
          </Stack>
        </Link>
        {imageLoader ? (
          <ImageSkeleton height={150} />
        ) : (
          <Avatar
            alt="Placeholder"
            variant="square"
            src={
              imageData === null
                ? Avtar
                : imageData
                ? `data:image/jpeg;base64,${imageData}`
                : Avtar
            }
            onClick={() => setOpenScreenShotModal(true)}
            sx={{ height: '150px', minWidth: '100%' }}
          />
        )}
        <CardContent>
          <GradientButton
            label="Threat Notes"
            sx={{ width: '100%' }}
            onClick={() => setOpenThreatNoteModal(true)}
          />
        </CardContent>
        <CardActions>
          <Tooltip title="Confirmed">
            <span>
              <Link
                to={`https://wheel.bfore.ai/threats/${items?.threat_name}/${items?.protected_asset_name}/${items?.company_id}`}
                target="_blank">
                <IconButton
                  sx={{ p: 0 }}
                  disabled={
                    items?.identification_status?.value === 'confirmed'
                      ? true
                      : false
                  }>
                  <CheckCircleIcon
                    fontSize="large"
                    sx={{
                      color:
                        items?.identification_status?.value === 'confirmed'
                          ? '#0000002e'
                          : '#50CD89',
                    }}
                  />
                </IconButton>
              </Link>
            </span>
          </Tooltip>
          <Tooltip title="Discarded">
            <span>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  handleOpenSingleModal('discarded');
                }}
                disabled={
                  items?.identification_status?.value === 'discarded'
                    ? true
                    : false
                }>
                <CancelIcon
                  fontSize="large"
                  sx={{
                    color:
                      items?.identification_status?.value === 'discarded'
                        ? '#0000002e'
                        : '#131628',
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Trademark">
            <span>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  handleOpenSingleModal('trademark');
                }}
                disabled={
                  items?.identification_status?.value === 'trademark'
                    ? true
                    : false
                }>
                <OfflinePinIcon
                  fontSize="large"
                  sx={{
                    color:
                      items?.identification_status?.value === 'trademark'
                        ? '#0000002e'
                        : '#7239EA',
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Owned">
            <span>
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  handleOpenSingleModal('owned');
                }}
                disabled={
                  items?.identification_status?.value === 'owned' ? true : false
                }>
                <PlaylistAddCircleIcon
                  fontSize="large"
                  sx={{
                    color:
                      items?.identification_status?.value === 'owned'
                        ? '#0000002e'
                        : '#25badb',
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
        <ScreenShotModal
          imageData={imageData}
          handleCloseScreenShot={handleCloseScreenShot}
          openScreenShotModal={openScreenShotModal}
        />
        <ThreatNotesModal
          openThreatNoteModal={openThreatNoteModal}
          handleCloseThreatNote={handleCloseThreatNote}
          items={items}
        />
        <ConfirmationModal
          title="Confirm status"
          subTitle="Do you want to change the status?"
          confirmLabel="Update"
          open={openModal}
          handleClose={() => setOpenModal(false)}
          handleUpdate={() => updateSingleStatus()}
        />
      </Card>
    </>
  );
};

export default ThreatCard;
